/* eslint-disable no-plusplus */
import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import Checkbox from "@mui/material/Checkbox";
import { TableContainer } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../../firebaseDb";

function BlockListDetail({ title, sprayInfo, shadow }) {
  function handleCheckboxChange(blockCode, varietyKey, checked) {
    // Step 1: Find and update the relevant variety in the local state
    const updatedSprayInfo = { ...sprayInfo };
    updatedSprayInfo.blockVarieties = updatedSprayInfo.blockVarieties.map((blockVariety) => {
      if (blockVariety.block.code === blockCode) {
        // eslint-disable-next-line no-param-reassign
        blockVariety.block.varieties = blockVariety.block.varieties.map((variety) => {
          if (variety.key === varietyKey) {
            return { ...variety, finalSpray: checked }; // Add or update 'finalSpray' field
          }
          return variety;
        });
      }
      return blockVariety;
    });

    // Step 2: Prepare the Firestore update
    const updatedBlockVarieties = updatedSprayInfo.blockVarieties;

    // Step 3: Update Firestore
    const docRef = doc(db, "Sprays", sprayInfo.key); // Replace with actual collection and document ID
    updateDoc(docRef, { blockVarieties: updatedBlockVarieties })
      .then(() => {
        console.log("Firestore updated successfully");
      })
      .catch((error) => {
        console.error("Error updating Firestore:", error);
      });
  }

  const filteredBlocks = sprayInfo.blockVarieties.filter((b) => b.block.checked);
  for (let i = 0; i < filteredBlocks.length; i++) {
    filteredBlocks[i].block.varieties = filteredBlocks[i].block.varieties.filter((v) => v.checked);
  }

  return (
    <Card sx={{ height: "100%", boxShadow: !shadow && "none" }}>
      <MDBox pt={2} px={2}>
        <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
          {title}
        </MDTypography>
      </MDBox>
      <TableContainer>
        <Table aria-label="simple table">
          <TableRow>
            <TableCell align="left">Block</TableCell>
            <TableCell align="center">Variety</TableCell>
            <TableCell align="center">Hectares</TableCell>
            <TableCell align="center">Final Spray</TableCell>
          </TableRow>

          <TableBody>
            {filteredBlocks.map((row, index) =>
              row.block.varieties.map((variety, varietyIndex) => (
                <TableRow
                  key={variety.key}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                    backgroundColor: index % 2 === 0 ? "#b2d6bb" : "#e3fad4",
                  }}
                >
                  {varietyIndex === 0 && ( // Only render the block cell for the first variety
                    <TableCell
                      align="left"
                      rowSpan={row.block.varieties.length} // Span all rows for this block
                    >
                      {row.block.code}
                    </TableCell>
                  )}
                  <TableCell align="center">{variety.variety}</TableCell>
                  <TableCell align="center">{variety.hectares}</TableCell>
                  <TableCell align="center">
                    <Checkbox
                      checked={variety.finalSpray}
                      onChange={(e) =>
                        handleCheckboxChange(row.block.code, variety.key, e.target.checked)
                      }
                    />
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
}

// Setting default props for the ProfilesList
BlockListDetail.defaultProps = {
  shadow: true,
};

// Typechecking props for the ProfilesList
BlockListDetail.propTypes = {
  title: PropTypes.string.isRequired,
  shadow: PropTypes.bool,
  sprayInfo: PropTypes.objectOf.isRequired,
};

export default BlockListDetail;
