// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Icon from "@mui/material/Icon";
import CircularProgress from "@mui/material/CircularProgress";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// React
import { useEffect, useState } from "react";
import { useAuth } from "layouts/authentication/AuthContext";
// Data
import spraysTableData from "layouts/sprays/data/spraysTableData";
// Firebase
import { db } from "firebaseDb";
import {
  getDocs,
  collection,
  query,
  where,
  setDoc,
  doc,
  getDoc,
  // updateDoc,
} from "firebase/firestore";

// import { saveAs } from "file-saver";

function Tables() {
  const [openDialog, setOpenDialog] = useState(false);
  const [openPublishDialog, setOpenPublishDialog] = useState(false);
  const [openDialogFinal, setOpenDialogFinal] = useState(false);
  const [openUnpublishDialog, setOpenUnpublishDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [sprayID, setID] = useState("");
  const [sprayInfo, setSprayInfo] = useState("");
  const [status, setStatus] = useState("Ready to Spray");
  const [dialogMessage, setDialogMessage] = useState("");
  const [cropsureData, setCropSureData] = useState([]);
  const [pageIndex, setPageIndex] = useState(0);
  const [globalFilter, setGlobalFilter] = useState("");

  const { user } = useAuth();
  const authToken = user.accessToken;

  const handleOpenDialog = (id) => {
    setOpenDialog(true);
    setID(id);
  };

  const handleOpenPublishDialog = (spray) => {
    setOpenPublishDialog(true);
    setID(spray.sprayInstructionId);
    setSprayInfo(spray);
  };

  const handleOpenUnpublishDialog = (id) => {
    setOpenUnpublishDialog(true);
    setID(id);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleCloseDialogFinal = () => {
    setOpenDialogFinal(false);
  };

  const handleClosePublishDialog = () => {
    setOpenPublishDialog(false);
  };

  const handleCloseUnpublishDialog = () => {
    setOpenUnpublishDialog(false);
  };

  // Delete Spray
  const deleteSpray = () => {
    setLoading(true);
    const sprayRef = collection(db, "Sprays");
    const q = query(sprayRef, where("sprayInstructionId", "==", sprayID));
    getDocs(q).then((querySnapshot) => {
      querySnapshot.forEach((docu) => {
        setDoc(doc(db, "Sprays", docu.id), { archive: true }, { merge: true }).then(() => {
          handleCloseDialog();
          setLoading(false);
        });
      });
    });
  };

  const checkFinal = (siteData, chems) => {
    // Format spray date
    const milliseconds = sprayInfo.sprayDate.seconds * 1000;
    const utcSprayDate = new Date(milliseconds);
    utcSprayDate.setHours(utcSprayDate.getHours() + 13);
    const formattedSprayDate = utcSprayDate.toISOString().split("T")[0];

    // Rearrange data
    const cropSureFormattedData = {
      orchardCode: sprayInfo.siteCode,
      cropSureData: [
        {
          sprayDate: formattedSprayDate,
          comments: sprayID.toString(),
          waterRate: Number(sprayInfo.waterRate),
          sprayedProducts: chems.map((chem) => ({
            chemProductId: parseInt(chem.chemID, 10),
            sprayRate: chem.chemRate,
            sprayUnitId: parseInt(chem.chemUnit, 10),
          })),
          sprayedVarieties: siteData[0].blockIDs.flatMap((block) =>
            block.varietyIDs.map((variety) => ({
              blockId: Number(block.blockID),
              varietyId: Number(variety.varietyID),
              isFinalSpray: Boolean(variety.isFinalSpray),
            }))
          ),
        },
      ],
    };
    setCropSureData(cropSureFormattedData);

    const hasFinalSpray = sprayInfo.blockVarieties.reduce((acc, blockData) => {
      const blockCode = blockData.block.code;
      const finalSprayVarieties = blockData.block.varieties.filter((v) => v.finalSpray);

      if (finalSprayVarieties.length > 0) {
        acc.push({
          block: blockCode,
          varieties: finalSprayVarieties.map((v) => v.variety),
        });
      }

      return acc;
    }, []);

    if (hasFinalSpray.length > 0) {
      let message = ""; // Temporary variable to build the full message
      hasFinalSpray.forEach((entry) => {
        message += `Block ${
          entry.block
        } has the following varieties with a final spray: ${entry.varieties.join(", ")}\n`;
      });
      setDialogMessage(message.trim()); // Trim to remove trailing newline
    } else {
      setDialogMessage("No varieties are marked as having a final spray. Is this correct?");
    }

    setOpenDialogFinal(true); // Open the dialog box
  };

  // Publish Spray
  const publishSpray = () => {
    setLoading(true);
    const myHeaders = new Headers({
      Authorization: `Bearer ${authToken}`,
      "Content-Type": "application/json",
      "x-api-key": "santaisrealbutyoumightnotbe",
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(cropsureData),
      redirect: "follow",
    };

    fetch("http://localhost:3002/publish", requestOptions)
      .then((response) => {
        if (!response.ok) {
          // Handle different response statuses with specific messages
          if (response.status === 401) {
            throw new Error("Unauthorized - Please check your credentials.");
          } else if (response.status === 500) {
            throw new Error("Server error - Please try again later.");
          } else {
            throw new Error(`Unexpected error: ${response.status} ${response.statusText}`);
          }
        }
        return response.json(); // Parse JSON only for successful responses
      })
      .then((result) => {
        console.log("API Response:", result);
        const sprayRef = collection(db, "Sprays");
        const q = query(sprayRef, where("sprayInstructionId", "==", sprayID));
        getDocs(q).then((querySnapshot) => {
          if (querySnapshot.empty) {
            alert("No matching sprays found to update.");
            setLoading(false);
            return;
          }

          querySnapshot.forEach((docu) => {
            setDoc(doc(db, "Sprays", docu.id), { status: "Published" }, { merge: true })
              .then(() => {
                alert("Success! Spray status updated to 'Published'.");
                handleClosePublishDialog();
                handleCloseDialogFinal();
                setLoading(false);
              })
              .catch((error) => {
                console.error("Firestore Error:", error);
                alert("Error: Failed to update Firestore. Please try again.");
                setLoading(false);
              });
          });
        });
      })
      .catch((error) => {
        console.error("API Error:", error);
        console.log(error.message);
        alert(`Error: ${error.message}`); // Display a user-friendly error message
        setLoading(false);
        handleClosePublishDialog();
        handleCloseDialogFinal();
      });
  };

  const fetchCropSureIDs = async () => {
    setLoading(true);
    const siteData = [];

    const blocksPromises = sprayInfo.blockVarieties.reduce(
      (promises, block) =>
        promises.concat(
          block.block.varieties
            .filter((variety) => block.block.checked && variety.checked)
            .map((variety) => {
              const blockRef = doc(db, "Orchards", variety.key);
              return getDoc(blockRef).then((docu) => {
                const siteID = docu.data().cropSureID;
                const blockID = docu.data().cropSureBlockID;
                const varietyID = docu.data().cropSureBlockVarietyID;

                const siteIndex = siteData.findIndex((site) => site.siteID === siteID);
                if (siteIndex === -1) {
                  // Add new site entry if not found
                  siteData.push({
                    siteID,
                    blockIDs: [
                      { blockID, varietyIDs: [{ varietyID, isFinalSpray: variety.finalSpray }] },
                    ],
                  });
                } else {
                  // Add block and variety to existing site entry
                  const blockIndex = siteData[siteIndex].blockIDs.findIndex(
                    (bl) => bl.blockID === blockID
                  );
                  if (blockIndex === -1) {
                    // Add new block entry if not found
                    siteData[siteIndex].blockIDs.push({
                      blockID,
                      varietyIDs: [{ varietyID, isFinal: variety.finalSpray }],
                    });
                  } else {
                    // Add variety to existing block entry
                    siteData[siteIndex].blockIDs[blockIndex].varietyIDs.push({
                      varietyID,
                      isFinal: variety.finalSpray,
                    });
                  }
                }
                return { siteID, blockID, varietyID };
              });
            })
        ),
      []
    );

    const codes = [
      {
        value: "13",
        label: "g/Ha",
        unit: "Gram",
      },
      {
        value: "5",
        label: "Kg/Ha",
        unit: "Kilogram",
      },
      {
        value: "4",
        label: "L/Ha",
        unit: "Litre",
      },
      {
        value: "12",
        label: "ml/Ha",
        unit: "Millilitre",
      },
    ];

    const chemsPromises = sprayInfo.chemicals.map((chem) => {
      if (chem.measure !== "Hectare") {
        alert("Measure is not Hectares! Bad things will happen");
      }
      const chemRef = doc(db, "Chemicals", chem.key);
      return getDoc(chemRef).then((docu) => {
        const { cropSureID } = docu.data();
        return {
          chemID: cropSureID,
          chemRate: Number(chem.sprayRate),
          chemUnit: codes.find((code) => code.unit === chem.unit).value,
        };
      });
    });

    try {
      const blocks = await Promise.all(blocksPromises);
      const chems = await Promise.all(chemsPromises);
      // Check if any of the IDs are undefined
      blocks.forEach(({ siteID, blockID, varietyID }) => {
        if (!siteID || !blockID || !varietyID) {
          alert("One of the IDs (siteID, blockID, varietyID) is undefined");
          throw new Error("One of the IDs (siteID, blockID, varietyID) is undefined");
        }
      });
      setLoading(false);
      checkFinal(siteData, chems);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
      alert("Error fetching data...");
      setOpenPublishDialog(false);
    }
  };

  // Unpublish Spray
  const unpublishSpray = () => {
    setLoading(true);
    const sprayRef = collection(db, "Sprays");
    const q = query(sprayRef, where("sprayInstructionId", "==", sprayID));
    getDocs(q).then((querySnapshot) => {
      querySnapshot.forEach((docu) => {
        setDoc(doc(db, "Sprays", docu.id), { status: "Sprayed" }, { merge: true }).then(() => {
          handleCloseUnpublishDialog();
          setLoading(false);
        });
      });
    });
  };

  const { columns, rows, clickEvent } = spraysTableData(
    handleOpenDialog,
    handleOpenPublishDialog,
    handleOpenUnpublishDialog,
    status
  );
  const [rowInfo, setRowInfo] = useState(
    rows
      .filter((row) => row.status === "Ready to Spray" && row.date !== "31/12/2999")
      .sort((a, b) => b.sprayID - a.sprayID)
  );

  const sortObjectsByDate = (objectArray) => {
    // Custom sorting function
    const customSort = (a, b) => {
      // Check if either date is empty
      if (a.date === "" && b.date === "") {
        return b.sprayID - a.sprayID; // Sort by sprayID if both dates are empty
      }
      if (a.date === "") {
        return 1; // Move entries with empty date to the bottom
      }
      if (b.date === "") {
        return -1; // Move entries with empty date to the bottom
      }

      // If dates are not empty, proceed to date sorting
      const dateA = new Date(`${a.date.split("/").reverse().join("-")}T00:00:00Z`).getTime();
      const dateB = new Date(`${b.date.split("/").reverse().join("-")}T00:00:00Z`).getTime();

      // Sort by date
      return dateB - dateA;
    };

    // Sort the array of objects using the custom sorting function
    objectArray.sort(customSort);

    return objectArray;
  };

  useEffect(() => {
    setRowInfo(() => sortObjectsByDate(rows.filter((row) => row.status === status)));
  }, [status, rows]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Dialog open={openDialogFinal} onClose={handleCloseDialogFinal}>
        <DialogTitle>Confirm Spray Details</DialogTitle>
        <DialogContent>
          <DialogContentText>{dialogMessage}</DialogContentText>
        </DialogContent>
        <DialogActions>
          {loading && (
            <MDBox justifyContent="center" display="flex">
              <CircularProgress />
            </MDBox>
          )}
          {!loading && (
            <>
              <MDButton onClick={handleCloseDialogFinal} color="secondary">
                Cancel
              </MDButton>
              <MDButton
                onClick={() => {
                  publishSpray();
                }}
                color="primary"
              >
                Confirm
              </MDButton>
            </>
          )}
        </DialogActions>
      </Dialog>

      <Dialog
        open={openUnpublishDialog}
        onClose={handleCloseUnpublishDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Unpublish Record?</DialogTitle>
        <DialogContent>
          {loading && (
            <MDBox justifyContent="center" display="flex">
              <CircularProgress />
            </MDBox>
          )}
          {!loading && (
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to unpublish this spray record?
            </DialogContentText>
          )}
        </DialogContent>

        {!loading && (
          <DialogActions>
            <MDButton onClick={handleCloseUnpublishDialog}>No</MDButton>
            <MDButton
              onClick={() => {
                unpublishSpray();
              }}
              autoFocus
            >
              Yes
            </MDButton>
          </DialogActions>
        )}
      </Dialog>
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Delete Record?</DialogTitle>
        <DialogContent>
          {loading && (
            <MDBox justifyContent="center" display="flex">
              <CircularProgress />
            </MDBox>
          )}
          {!loading && (
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to delete this spray record?
            </DialogContentText>
          )}
        </DialogContent>

        {!loading && (
          <DialogActions>
            <MDButton onClick={handleCloseDialog}>No</MDButton>
            <MDButton
              onClick={() => {
                deleteSpray();
              }}
              autoFocus
            >
              Yes
            </MDButton>
          </DialogActions>
        )}
      </Dialog>
      <Dialog
        open={openPublishDialog}
        onClose={handleClosePublishDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Publish Record?</DialogTitle>
        <DialogContent>
          {loading && (
            <MDBox justifyContent="center" display="flex">
              <CircularProgress />
            </MDBox>
          )}
          {!loading && (
            <DialogContentText id="alert-dialog-description">
              Publish this spray to Cropsure?
            </DialogContentText>
          )}
        </DialogContent>
        {!loading && (
          <DialogActions>
            <MDButton onClick={handleClosePublishDialog}>No</MDButton>
            <MDButton
              onClick={() => {
                fetchCropSureIDs();
              }}
              autoFocus
            >
              Yes
            </MDButton>
          </DialogActions>
        )}
      </Dialog>
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="success"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Sprays Table
                </MDTypography>
                <MDBox justifyContent="center" display="flex">
                  <MDButton
                    size="medium"
                    sx={{ marginLeft: "10px" }}
                    color={status === "Ready to Spray" ? "primary" : "secondary"}
                    onClick={() => {
                      setStatus("Ready to Spray");
                    }}
                  >
                    Ready to Spray
                    <Icon>checklist</Icon>
                  </MDButton>
                  <MDButton
                    size="medium"
                    sx={{ marginLeft: "10px" }}
                    color={status === "Sprayed" ? "primary" : "secondary"}
                    onClick={() => {
                      setStatus("Sprayed");
                    }}
                  >
                    Sprayed
                    <Icon>waterdrop</Icon>
                  </MDButton>
                  <MDButton
                    size="medium"
                    sx={{ marginLeft: "10px" }}
                    color={status === "Published" ? "primary" : "secondary"}
                    onClick={() => {
                      setStatus("Published");
                    }}
                  >
                    Published
                    <Icon>backup</Icon>
                  </MDButton>
                  {/* <MDButton
                    size="medium"
                    sx={{ marginLeft: "10px" }}
                    color="white"
                    onClick={() => {
                      updateSpraysCollection();
                    }}
                  >
                    Update Sprays
                    <Icon>checklist</Icon>
                  </MDButton> */}
                </MDBox>
              </MDBox>
              <MDBox pt={3}>
                {rowInfo.length > 0 && (
                  <DataTable
                    table={{ columns, rows: rowInfo, clickEvent }}
                    entriesPerPage
                    showTotalEntries
                    canSearch
                    isSorted={false}
                    initialPageIndex={pageIndex}
                    initialGlobalFilter={globalFilter}
                    onPageChange={setPageIndex}
                    onGlobalFilterChange={setGlobalFilter}
                  />
                )}
                {rowInfo.length === 0 && (
                  <MDBox justifyContent="center" display="flex" marginBottom="20px">
                    <CircularProgress />
                  </MDBox>
                )}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Tables;
