/* eslint-disable no-plusplus */
/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
// import MDBadge from "components/MDBadge";

import { useNavigate } from "react-router-dom";

import { useEffect, useState } from "react";
import { db } from "firebaseDb";
import { query, where, onSnapshot, collection } from "firebase/firestore";
import { Icon } from "@mui/material";

export default function data(
  handleOpenDialog,
  handleOpenPublishDialog,
  handleOpenUnpublishDialog,
  status
) {
  const navigate = useNavigate();
  const [sprays, setSprays] = useState([]);
  const [isCopyButtonClicked, setIsCopyButtonClicked] = useState(false);

  const Blocks = ({ blocks }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDBox ml={1} lineHeight={3} sx={{ maxWidth: "150px" }}>
        {blocks.split(",").map((b) => (
          <MDTypography key={b} display="block" variant="button" fontWeight="medium">
            {b}
          </MDTypography>
        ))}
      </MDBox>
    </MDBox>
  );

  useEffect(() => {
    const eightMonthsAgo = new Date();
    eightMonthsAgo.setMonth(eightMonthsAgo.getMonth() - 8);
    const sprayRef = collection(db, "Sprays");

    const q1 = query(
      sprayRef,
      where("sprayDate", ">=", eightMonthsAgo),
      where("archive", "==", false)
    );

    const q2 = query(sprayRef, where("sprayDate", "==", ""), where("archive", "==", false));

    const updateSprays = (snapshot1, snapshot2) => {
      const spraysData = [];

      const processSnapshot = (snapshot) => {
        snapshot.forEach((docu) => {
          let blockString = "";
          const blockStringArray = [];

          if (typeof docu.data().blockVarieties !== "string") {
            for (let i = 0; i < docu.data().blockVarieties.length; i++) {
              if (docu.data().blockVarieties[i].block.checked) {
                blockStringArray.push(`Block ${docu.data().blockVarieties[i].block.code}`);
              }
            }

            blockString =
              blockStringArray.length === docu.data().blockVarieties.length
                ? "All Blocks"
                : blockStringArray.join(",");
          } else {
            blockString = docu.data().blockVarieties;
          }

          spraysData.push({
            sprayID: docu.data().sprayInstructionId,
            orchard: [docu.data().siteCode, docu.data().siteName].join("\r\n"),
            date:
              typeof docu.data().sprayDate === "string"
                ? docu.data().sprayDate.slice(0, -9)
                : docu.data().sprayDate.toDate().toLocaleString("en-GB", {
                    year: "numeric",
                    month: "numeric",
                    day: "numeric",
                  }),
            status: docu.data().status,
            weedspray: docu.data().weedspray,
            operator:
              typeof docu.data().operatorWorker === "string"
                ? docu.data().operatorWorker.split(",").join("\r\n")
                : docu.data().operatorWorker.join("\r\n"),
            chemicals:
              typeof docu.data().chemicals === "string"
                ? docu.data().chemicals.split(",").join("\r\n")
                : docu
                    .data()
                    .chemicals.map((c) => c.chemicalName)
                    .join("\r\n"),
            blocks: <Blocks blocks={blockString} />,
            action: (
              <MDBox>
                {docu.data().status !== "Published" && (
                  <MDButton
                    size="medium"
                    iconOnly
                    color="info"
                    disabled={docu.data().sprayInstructionId < 20000}
                    onClick={(event) => {
                      event.stopPropagation();
                      setIsCopyButtonClicked(true);
                      navigate("/sprays/clonespray", {
                        state: {
                          data: {
                            ...docu.data(),
                            sprayDate: docu.data().sprayDate ? docu.data().sprayDate.toDate() : "",
                          },
                        },
                      });
                    }}
                  >
                    <Icon>copy</Icon>
                  </MDButton>
                )}
                {docu.data().status !== "Published" && (
                  <MDButton
                    size="medium"
                    iconOnly
                    color="warning"
                    sx={{ marginLeft: "3px" }}
                    disabled={docu.data().sprayInstructionId < 20000}
                    onClick={(event) => {
                      event.stopPropagation(); // Stop the event from propagating to the row click event handler
                      setIsCopyButtonClicked(true);
                      navigate(`/editspray/${docu.data().sprayInstructionId}`, {
                        state: {
                          data: {
                            ...docu.data(),
                            sprayDate: docu.data().sprayDate ? docu.data().sprayDate.toDate() : "",
                            key: docu.id,
                          },
                        },
                      });
                    }}
                  >
                    <Icon>edit</Icon>
                  </MDButton>
                )}
                {docu.data().status !== "Published" && (
                  <MDButton
                    size="medium"
                    iconOnly
                    sx={{ marginLeft: "3px" }}
                    color="error"
                    disabled={docu.data().sprayInstructionId < 20000}
                    onClick={(event) => {
                      event.stopPropagation(); // Stop the event from propagating to the row click event handler
                      setIsCopyButtonClicked(true);
                      handleOpenDialog(docu.data().sprayInstructionId);
                    }}
                  >
                    <Icon>delete</Icon>
                  </MDButton>
                )}
                {docu.data().status === "Sprayed" && (
                  <MDButton
                    size="medium"
                    iconOnly
                    sx={{ marginLeft: "3px" }}
                    color="secondary"
                    disabled={docu.data().sprayInstructionId < 20000}
                    onClick={(event) => {
                      event.stopPropagation(); // Stop the event from propagating to the row click event handler
                      setIsCopyButtonClicked(true);
                      handleOpenPublishDialog(docu.data());
                    }}
                  >
                    <Icon>publish</Icon>
                  </MDButton>
                )}
                {docu.data().status === "Published" && (
                  <MDButton
                    size="medium"
                    iconOnly
                    sx={{ marginLeft: "3px" }}
                    color="primary"
                    disabled={docu.data().sprayInstructionId < 20000}
                    onClick={(event) => {
                      event.stopPropagation(); // Stop the event from propagating to the row click event handler
                      setIsCopyButtonClicked(true);
                      handleOpenUnpublishDialog(docu.data().sprayInstructionId);
                    }}
                  >
                    <Icon>settings_backup_restore</Icon>
                  </MDButton>
                )}
              </MDBox>
            ),
          });
        });
      };

      processSnapshot(snapshot1);
      processSnapshot(snapshot2);

      setSprays(spraysData); // Replaces previous state with the new data set
    };

    const unsubscribe1 = onSnapshot(q1, (snapshot1) => {
      const unsubscribe2 = onSnapshot(q2, (snapshot2) => {
        updateSprays(snapshot1, snapshot2);
      });

      return unsubscribe2; // Ensure both snapshots are unsubscribed on cleanup
    });

    return () => {
      unsubscribe1();
    };
  }, []);

  if (status === "Ready to Spray") {
    return {
      columns: [
        { Header: "spray id", accessor: "sprayID", align: "left" },
        { Header: "Orchard", accessor: "orchard", align: "left" },
        { Header: "blocks", accessor: "blocks", align: "center" },
        { Header: "operator", accessor: "operator", align: "center" },
        { Header: "chemicals", accessor: "chemicals", align: "center" },
        { Header: "action", accessor: "action", align: "center" },
      ],

      rows: sprays,
      clickEvent: isCopyButtonClicked,
    };
  }
  return {
    columns: [
      { Header: "spray id", accessor: "sprayID", align: "left" },
      { Header: "date", accessor: "date", align: "left" },
      { Header: "Orchard", accessor: "orchard", align: "left" },
      { Header: "blocks", accessor: "blocks", align: "center" },
      { Header: "operator", accessor: "operator", align: "center" },
      { Header: "chemicals", accessor: "chemicals", align: "center" },
      { Header: "action", accessor: "action", align: "center" },
    ],

    rows: sprays,
    clickEvent: isCopyButtonClicked,
  };
}
