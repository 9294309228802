/* eslint-disable no-param-reassign */
/* eslint-disable no-plusplus */
// react-routers components
// import { Link } from "react-router-dom";

// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// Styles
import "./styles.css";
// React
import { useEffect, useState } from "react";

// @mui material components
import Card from "@mui/material/Card";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// ExcelJS for exporting data
import ExcelJS from "exceljs";
import { saveAs } from "file-saver"; // To save the file on the client side

const totalChemical = (
  amount,
  measure,
  hectares,
  unit,
  waterRate,
  tankCapacity,
  actualChemical
) => {
  const tanksperHectare = waterRate / tankCapacity;

  if (actualChemical) {
    return {
      text: `${Math.round(100 * actualChemical) / 100} ${unit}`,
      number: Math.round(100 * actualChemical) / 100,
    };
  }
  if (measure === "Tank") {
    return {
      text: `${Math.round(100 * amount * tanksperHectare * hectares) / 100} ${unit}`,
      number: Math.round(100 * amount * tanksperHectare * hectares) / 100,
    };
  }
  if (measure === "Hectare") {
    return {
      text: `${Math.round(100 * Number(amount) * hectares) / 100} ${unit}`,
      number: Math.round(100 * Number(amount) * hectares) / 100,
    };
  }
  if (measure === "1000 L") {
    return {
      text: `${Math.round((100 * Number(amount) * waterRate * hectares) / 1000) / 100} ${unit}`,
      number: Math.round((100 * Number(amount) * waterRate * hectares) / 1000) / 100,
    };
  }

  return null;
};

const TimestamptoDate = (timestamp) => {
  const { seconds } = timestamp;
  const { nanoseconds } = timestamp;

  const milliseconds = seconds * 1000 + nanoseconds / 1e6;
  const date = new Date(milliseconds).toDateString();
  return date;
};

function InvoiceSprayListGrid({ title, invoiceData, shadow, chemicals, startDate, endDate }) {
  const [rows, setRows] = useState([]);
  useEffect(() => {
    const temprows = [];
    for (let i = 0; i < invoiceData.length; i++) {
      const orchard = invoiceData[i];
      let orchardTotalCost = 0;

      // Loop through sprays in the orchard
      for (let j = 0; j < orchard.sprays?.length; j++) {
        const spray = orchard.sprays[j];
        if (spray.weedspray) {
          temprows.push({
            unBilled: spray.unBilled,
            id: `${i}${j}`,
            orchard: j === 0 ? orchard.siteName : "--",
            date: TimestamptoDate(spray.sprayDate),
            chemicalName: spray.chemicalName,
            totalused: spray.totalused,
            cost: spray.unBilled ? "Unbilled" : `$${spray.unitCost.toFixed(2)} per Unit`,
            chemicalcost: `$${spray.chemicalcost.toFixed(2)}`,
            hectares: spray.hectares,
            percent: `$${(spray.chemicalCost * 0.1).toFixed(2)}`,
            billablecost: `$${(spray.chemicalCost * 1.1).toFixed(2)}`,
          });
        }
        // Loop through chemicals in each spray
        for (let k = 0; k < spray.chemicals?.length; k++) {
          const chem = spray.chemicals[k];

          // Calculate reused values to avoid duplication
          const chemical = chemicals.chemicals.find((c) => c.key === chem.key);
          const totalUsed = totalChemical(
            chem.sprayRate,
            chem.measure,
            spray.hectares,
            chem.unit,
            spray.waterRate,
            spray.tankCapacity,
            chem.actualChemical
          );
          let chemicalCost = 0;
          if (!chem.unBilled) {
            chemicalCost = totalUsed.number * chemical.cost;
          }

          const billableCost = 1.1 * chemicalCost;
          const percentCost = 0.1 * chemicalCost;

          // Accumulate orchard total cost
          orchardTotalCost += chemicalCost;

          temprows.push({
            unBilled: chem.unBilled,
            id: `${i}${j}${k}`,
            orchard: k === 0 && j === 0 ? orchard.siteName : "--",
            date: k === 0 ? TimestamptoDate(spray.sprayDate) : "--",
            chemicalName: chem.chemicalName,
            totalused: totalUsed.text,
            cost: chem.unBilled ? "Unbilled" : `$${chemical.cost} per Unit`,
            chemicalcost: `$${chemicalCost.toFixed(2)}`,
            hectares: k === 0 ? spray.hectares : "--",
            percent: `$${percentCost.toFixed(2)}`,
            billablecost: `$${billableCost.toFixed(2)}`,
          });
        }
      }

      // After processing all sprays for the orchard, add the total cost row
      temprows.push({
        id: `total-${i}`,
        orchard: `Total Orchard Cost`,
        date: "--",
        chemicalName: "--",
        totalused: "--",
        cost: "--",
        chemicalcost: "--",
        hectares: "--",
        percent: "--",
        billablecost: `$${(1.1 * orchardTotalCost).toFixed(2)}`,
      });
    }

    setRows(temprows);
  }, [invoiceData]);

  const columns = [
    { field: "orchard", headerName: "Orchard", width: 250 },
    { field: "date", headerName: "Date", width: 150 },
    { field: "chemicalName", headerName: "Chemical", width: 100 },
    { field: "hectares", headerName: "Ha", width: 60 },
    { field: "totalused", headerName: "Total Used", width: 130 },
    { field: "cost", headerName: "Cost", width: 150 },
    { field: "chemicalcost", headerName: "Chem Cost", width: 100 },
    { field: "percent", headerName: "10%", width: 100 },
    { field: "billablecost", headerName: "Billable", width: 100 },
  ];

  const TimestamptoNZDate = (timestamp) => {
    // Convert to milliseconds
    const milliseconds = timestamp.seconds * 1000 + timestamp.nanoseconds / 1e6;

    // Create a Date object
    const date = new Date(milliseconds);

    // Format to "Sept 8 2024" in New Zealand Time (NZT)
    const options = { year: "numeric", month: "short", day: "numeric" };
    const nztDate = date.toLocaleDateString("en-NZ", { ...options, timeZone: "Pacific/Auckland" });

    return nztDate;
  };
  const exportToExcel = () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Invoice Data");

    // Add columns to the worksheet (use column headers)
    worksheet.columns = columns.map((col) => ({
      header: col.headerName,
      key: col.field,
      width: 20,
    }));

    // Apply bottom border to the header
    worksheet.getRow(1).eachCell((cell) => {
      cell.font = { bold: true };
      cell.alignment = { vertical: "middle", horizontal: "center" };
      cell.border = {
        bottom: { style: "thick", color: { argb: "FF000000" } }, // Black bottom border
      };
    });

    // Add rows to the worksheet
    rows.forEach((row) => {
      const excelRow = worksheet.addRow(row);

      // Change background color for first row of each orchard
      if (row.orchard !== "--" && !row.orchard.includes("Total")) {
        excelRow.eachCell((cell) => {
          cell.border = {
            top: { style: "thin", color: { argb: "FF000000" } }, // Black bottom border
          };

          cell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "FFE5E5E5" }, // Light gray background for first orchard row
          };
        });
      }

      // Change background color for the total of each orchard
      if (row.orchard.includes("Total")) {
        excelRow.eachCell((cell) => {
          cell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "b4eeb4" }, // Light gray background for first orchard row
          };
        });
      }

      // Additional styling based on row conditions (like unBilled rows)
      if (row.unBilled) {
        excelRow.eachCell((cell) => {
          cell.font = { color: { argb: "FFFF0000" } }; // Red font for unBilled rows
        });
      }
    });

    // Make the header row sticky by freezing it
    worksheet.views = [
      { state: "frozen", ySplit: 1 }, // Freeze the first row
    ];

    // Save the Excel file
    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const fileName = `${TimestamptoNZDate(startDate)} - ${TimestamptoNZDate(
        endDate
      )} Invoices.xlsx`;
      saveAs(blob, fileName);
    });
  };

  const getCellClassName = (params) => {
    const { field, row } = params;
    if (
      ["chemicalName", "totalused", "cost", "chemicalcost", "hectares", "spraycost"].includes(
        field
      ) &&
      row.unBilled
    ) {
      return "unbilled-cell";
    }
    if (["orchard", "billablecost"].includes(field) && row.orchard.includes("Total")) {
      return "total-cell";
    }
    return "";
  };

  return (
    <Card sx={{ height: "100%", boxShadow: !shadow && "none" }}>
      <MDBox pt={4} px={2} pb={3}>
        <MDTypography variant="h4" fontWeight="medium" textTransform="capitalize">
          {title}
        </MDTypography>
        <MDButton
          color="primary"
          // disabled={!table.getIsSomeRowsSelected()}
          onClick={exportToExcel}
          variant="contained"
        >
          Download Excel File
        </MDButton>
      </MDBox>
      <DataGrid
        rows={rows}
        columns={columns}
        getCellClassName={getCellClassName}
        slots={{ toolbar: GridToolbar }}
      >
        {" "}
      </DataGrid>
    </Card>
  );
}

// Setting default props for the ProfilesList
InvoiceSprayListGrid.defaultProps = {
  shadow: true,
};

// Typechecking props for the ProfilesList
InvoiceSprayListGrid.propTypes = {
  title: PropTypes.string.isRequired,
  shadow: PropTypes.bool,
  invoiceData: PropTypes.objectOf.isRequired,
  chemicals: PropTypes.objectOf.isRequired,
};

export default InvoiceSprayListGrid;
